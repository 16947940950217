import React from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }
  return (
    <div
      style={{
        backgroundColor: '#f0f0f0',
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "50px",
      }}
    >
      <Container>
        <h1 className="text-center text-black mb-4">Admin Panel</h1>
        <Row className="d-flex justify-content-center ">
          <div className="col-xs-12 col-md-3 mb-4 px-2 text-center">
            <Link to="/userlist" className="btn btn-primary btn-lg btn-block">
              User List
            </Link>
          </div>
          <div className="col-xs-12 col-md-3 mb-4 px-2 text-center">
            <Link to="/productlist" className="btn btn-success btn-lg btn-block">
              Add Invoice
            </Link>
          </div>
          <div className="col-xs-12 col-md-3 mb-4 px-2 text-center">
            <button className="btn btn-danger btn-lg btn-block" onClick={logoutSubmit}>
              Logout
            </button>
          </div>
        </Row>
      </Container>
    </div>
  );
  
}

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

function EditUser() {
  const navigate = useNavigate();
  const { userid } = useParams();
  const location = useLocation();
  const [user, setUser] = useState({
    user: "",
    pass: "",
    status: ""
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (location.state && location.state.user) {
      setUser(location.state.user);
    } else {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`https://artinvver.com/user.php/${userid}`); 
          setUser(response.data);
        } catch (error) {
          console.error("Error fetching Invoice details:", error);
        }
      };
      fetchUserDetails();
    }
  }, [userid, location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://artinvver.com/user.php/${userid}`, user);
      if (response.data.success) {
        setMessage(response.data.success);
        setTimeout(() => {
          navigate("/userlist");
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mt-4">
          <h5 className="mb-4">Edit User</h5>
          <p className="text-success">{message}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 row">
              <label className="col-sm-2 col-form-label">User</label>
              <div className="col-sm-10">
                <input type="text" name="user" value={user.user} className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-form-label">Password</label>
              <div className="col-sm-10">
                <input type="text" name="pass" value={user.pass} className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-2 col-form-label">Status</label>
              <div className="col-sm-10">
                <select name="status" className="form-select" value={user.status} onChange={handleChange}>
                  <option value="">--Select Status--</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-sm-10 offset-sm-2">
                <button type="submit" name="submit" className="btn btn-primary">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditUser;

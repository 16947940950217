import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function EditProduct() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState({
    'Vehicle Stock': '',
    'Sale Date': '',
    'Year': '',
    'Make': '',
    'Model': '',
    'Vehicle Vin': '',
    'Vehicle Price': '',
    'Buyer Company': '',
    'Buyer Address': '',
    'Buyer City': '',
    'Total Sales': '',
    'Buyer RFC': '',
    'Verified': ''
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`https://artinvver.com/product.php/${productId}`);
        console.log("Response:", response);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching Invoice details:", error);
      }
    };
    fetchProductDetails();
  }, [productId]);

  const updateProduct = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://artinvver.com/product.php/${productId}`, product);
      if (response.data.success) {
        setMessage('Invoice updated successfully!');
        setTimeout(() => {
          navigate('/productlist');
        }, 2000);
      } else {
        setMessage('Failed to update Invoice.');
      }
    } catch (error) {
      console.error("Error updating Invoice:", error);
      setMessage('Failed to update Invoice.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 mt-4">
          <h5 className="mb-4">Edit Invoice</h5>
          <p className="text-warning">{message}</p>
          <form onSubmit={updateProduct}>
          {Object.entries(product).map(([key, value]) => (
  <div className="mb-3 row" key={key}>
    <label className="col-sm-3 col-form-label">{key}</label>
    <div className="col-sm-9">
      <input
        type="text"
        className="form-control"
        name={key}
        value={value || ''}
        onChange={handleChange}
      />
    </div>
  </div>
))}
            <div className="row">
              <div className="col-sm-9 offset-sm-3">
                <button type="submit" className="btn btn-success">
                  Update Invoice
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;

import React, { useState, useEffect } from 'react';
import logo from './assests/logo.jpeg';
import { useNavigate } from 'react-router-dom';

export default function Login({ onLogout }) {
    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        if (localStorage.getItem('login')) {
            onLogout();
            navigate('/dashboard');
        }
        if (localStorage.getItem('loginStatus')) {
            setError(localStorage.loginStatus);
            localStorage.removeItem('loginStatus');
        }
    }, [navigate, onLogout]);

 
    const handleInputChange = (e, type) => {
        switch(type){
            case "user":
                setError("");
                setUser(e.target.value);
                if(e.target.value === ""){
                    setError("Username has been left blank");
                }
                break;
            case "pass":
                setError("");
                setPass(e.target.value);
                if(e.target.value === ""){
                    setError("Password has been left blank");
                }
                break;
            default:
        }
    }
 
    function loginSubmit() {
        if (user !== "" && pass !== "") {
            var url = "https://artinvver.com/login.php";
            var headers = {
                "Accept": "application/json",
                "Content-type": "application/json"
            };
            var Data = {
                user: user,
                pass: pass
            };
            fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(Data)
            }).then((response) => response.json())
            .then((response) => {
                console.log(response);
                if (response[0].result === "Invalid username!" || response[0].result === "Invalid password!") {
                    setError(response[0].result);
                } else {
                    setMsg(response[0].result);
                    setTimeout(function () {
                        localStorage.setItem("login", true);
                        localStorage.setItem('user', user);
                        localStorage.setItem('role', response[0].role);
                        if (response[0].role === "admin") {
                            navigate("/dashboard");
                        } else {
                            navigate("/userdetail");
                        }
                    }, 1000);
                }
            }).catch((err) => {
                setError(err);
                console.log(err);
            });
        } else {
            setError("All fields are required!");
        }
    }

    return (
        <>
            <section className="vh-100" style={{ backgroundColor: '#f0f0f0' }}>
                <div className="container py-3 h-100 d-flex justify-content-center align-items-center">
                    <div className="card custom-card" style={{ borderRadius: '1rem', maxWidth: '400px', width: '100%' }}>
                        <div className="d-flex align-items-center">
                            <div className="card-body p-3 p-lg-4 text-black" style={{ fontSize: '0.9rem' }}>
                                <p>
                                    {error !== "" ?
                                        <div style={{ color: '#842029' }}><b>{error}</b></div> :
                                        <div style={{ color: '#badbcc' }}><b>{msg}</b></div>
                                    }
                                </p>
                                <div className="d-flex align-items-center justify-content-center mb-3 pb-1">
                                    <img src={logo} alt="login form" className="img-fluid logo" style={{ maxWidth: '100%', height: 'auto' }} />
                                </div>
                                <h5 className="fw-normal mb-2 pb-2 text-center" style={{ letterSpacing: 1, fontSize: '1.2rem' }}>Login into your account</h5>
                                <div className="form-outline mb-3">
                                    <input 
                                        type="text"
                                        id="username"
                                        className="form-control form-control-lg"
                                        value={user}
                                        onChange={(e) => handleInputChange(e, "user")}
                                        style={{ fontSize: '1.2rem' }}
                                    />
                                    <label className="form-label" htmlFor="username" style={{ fontSize: '1rem' }}>User Name</label>
                                </div>
                                <div className="form-outline mb-3">
                                    <input 
                                        type="password"
                                        id="pass"
                                        className="form-control form-control-lg"
                                        value={pass}
                                        onChange={(e) => handleInputChange(e, "pass")}
                                        style={{ fontSize: '1.2rem' }}
                                    />
                                    <label className="form-label" htmlFor="pass" style={{ fontSize: '1rem' }}>Password</label>
                                </div>
                                <div className="pt-1 mb-3">
                                    <button
                                        className="btn btn-primary btn-lg btn-block"
                                        onClick={loginSubmit}
                                        style={{ fontSize: '1.2rem' }}
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
} 

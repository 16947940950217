import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function AddUser() {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({ user: "", pass: "", status: "" });
  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { user: formValue.user, pass: formValue.pass, status: formValue.status };
    // const res = await axios.post("https://artinvver.com/user.php", formData);
    const res = await axios.post("https://artinvver.com/user.php", formData);

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/userlist");
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-4">
            <h5 className="mb-4">Add User</h5>
            <p className="text-success">{message}</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 row">
                <label className="col-sm-2">User Name</label>
                <div className="col-sm-10">
                  <input type="text" name="user" value={formValue.user} className="form-control" onChange={handleInput} />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-sm-2">Password</label>
                <div className="col-sm-10">
                  <input type="text" name="pass" value={formValue.pass} className="form-control" onChange={handleInput} />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-sm-2">Status</label>
                <div className="col-sm-10">
                  <select type="text" name="status" className="form-control" value={formValue.status} onChange={handleInput}>
                    <option value="">--Select status--</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-sm-2"></label>
                <div className="col-sm-10">
                  <button name="submit" className="btn btn-success">Add</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddUser;
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Addproduct() {
    const navigate = useNavigate();
    const [productDetails, setProductDetails] = useState({
        'Vehicle Stock': '',
        'Sale Date': '',
        'Year': '',
        'Make': '',
        'Model': '',
        'Vehicle Vin': '',
        'Vehicle Price': '',
        'Buyer Company': '',
        'Buyer Address': '',
        'Buyer City': '',
        'Total Sales': '',
        'Buyer RFC': '',
        'Verified': ''

    });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setProductDetails({
            ...productDetails,
            [e.target.name]: e.target.value,
        });
    };

    const addProduct = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://artinvver.com/product.php/", productDetails);
            if (response.data.success) {
                setMessage('Invoice added successfully!');
                setTimeout(() => {
                    navigate('/productlist');
                }, 2000);
            }
        } catch (error) {
            console.error("Error adding Invoice:", error);
            setMessage('Failed to add Invoice.');
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8 mt-4">
                    <h5 className="mb-4">Add Invoice Details</h5>
                    <p className="text-warning">{message}</p>
                    <form onSubmit={addProduct}>
                        {Object.keys(productDetails).map((detail) => (
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">{detail}</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={detail}
                                        value={productDetails[detail]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-sm-9 offset-sm-3">
                                <button type="submit" className="btn btn-success">
                                    Add Invoice
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Addproduct;


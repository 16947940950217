import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Userdetail() {
    const [vin, setVIN] = useState('');
    const [vehicleData, setVehicleData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    function logoutSubmit() {
        localStorage.setItem("login", "");
        localStorage.setItem("loginStatus", "Logged out successfully!");
        navigate("/");
    }

    const handleSearch = async () => {
        try {
            const response = await fetch(`https://artinvver.com/product.php/?vin=${vin}`);
            const data = await response.json();
            if (data.error) {
                setError('VIN NOT FOUND');
                setVehicleData(null);
            } else {
                setVehicleData(data);
                setError(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="App" style={{ textAlign: 'center' }}>
            <div className="background-image" style={{
                backgroundColor: '#f0f0f0',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div className="container">
                    <h1 style={{ color: 'black' }}>WELCOME</h1>
                    <p style={{ color: 'black' }}>Invoice Validation</p>
                    <div className="row justify-content-center">
                        <div className="col-xs-8 col-md-6 col-lg-4">
                            <form className="search-form" style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                padding: '20px',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <input
                                    type="text"
                                    className="form-control mr-sm-2"
                                    placeholder="Enter Vehicle VIN"
                                    value={vin}
                                    onChange={(e) => setVIN(e.target.value)}
                                />
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={handleSearch}
                                >
                                    Search
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-xs-8 col-md-6 col-lg-4">
                            <button onClick={logoutSubmit} className="btn btn-danger">Logout</button>
                        </div>
                    </div>
                </div>
                {error && (
                        <div className="alert alert-danger mt-4" role="alert">
                            {error}
                        </div>
                    )}
                {vehicleData && (
                    <div className="text-black container">
                        <h2>Invoice Details</h2>
                        <table className="text-black table table-bordered">
                            
                            <tbody>
                                <tr>
                                    <td>Invoice No</td>
                                    <td>{vehicleData['Vehicle Stock']}</td>
                                </tr>
                                <tr>
                                    <td>Sale Date</td>
                                    <td>{vehicleData['Sale Date']}</td>
                                </tr>
                                <tr>
                                    <td>Year</td>
                                    <td>{vehicleData.Year}</td>
                                </tr>
                                <tr>
                                    <td>Make</td>
                                    <td>{vehicleData.Make}</td>
                                </tr>
                                <tr>
                                    <td>Model</td>
                                    <td>{vehicleData.Model}</td>
                                </tr>
                                <tr>
                                    <td>Full Vin</td>
                                    <td>{vehicleData['Vehicle Vin']}</td>
                                </tr>
                                <tr>
                                    <td>Sales Price</td>
                                    <td>{vehicleData['Vehicle Price']}</td>
                                </tr>
                                <tr>
                                    <td>Buyers Name</td>
                                    <td>{vehicleData['Buyer Company']}</td>
                                </tr>
                                <tr>
                                    <td>Buyers Address</td>
                                    <td>{vehicleData['Buyer Address']}</td>
                                </tr>
                                <tr>
                                    <td>Buyers City</td>
                                    <td>{vehicleData['Buyer City']}</td>
                                </tr>
                                <tr>
                                    <td>Buyer RFC</td>
                                    <td>{vehicleData['Buyer RFC']}</td>
                                </tr>
                                <tr>
                                    <td>Total Sale</td>
                                    <td>{vehicleData['Total Sales']}</td>
                                </tr>
                                <tr>
                                    <td>Verified</td>
                                    <td>{vehicleData.Verified}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

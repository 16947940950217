import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Productlist() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch("https://artinvver.com/product.php");
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchProducts();
    }, []);

    const deleteProduct = async (productId) => {
        try {
          const response = await fetch(`https://artinvver.com/product.php?id=${productId}`, {
            method: "DELETE",
          });
          if (response.ok) {
            const updatedProducts = products.filter(product => `${product.Invoice}` !== `${productId}`);
            setProducts(updatedProducts);
          } else {
            console.error("Failed to delete Invoice");
          }
        } catch (error) {
          console.error("Error deleting Invoice:", error);
        }
      };
      

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <h5 className="mb-4">Invoice List</h5>
                        <Link to="/addproduct" className="btn btn-primary btn-lg btn-block mb-3">Add Invoice</Link>
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="thead-dark">
                                    <tr>
                                        <th className="small">Vehicle Stock</th>
                                        <th className="small">Sale Date</th>
                                        <th className="small">Year</th>
                                        <th className="small">Make</th>
                                        <th className="small">Model</th>
                                        <th className="small">Vehicle Vin</th>
                                        <th className="small">Vehicle Price</th>
                                        <th className="small">Buyer Company</th>
                                        <th className="small">Buyer Address</th>
                                        <th className="small">Buyer City</th>
                                        <th className="small">Total Sales</th>
                                        <th className="small">Buyer RFC</th>
                                        <th className="small">Verified</th>
                                        <th className="small">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((product, index) => (
                                        <tr key={index}>
                                            <td className="small">{product['Vehicle Stock']}</td>
                                            <td className="small">{product['Sale Date']}</td>
                                            <td className="small">{product['Year']}</td>
                                            <td className="small">{product['Make']}</td>
                                            <td className="small">{product['Model']}</td>
                                            <td className="small">{product['Vehicle Vin']}</td>
                                            <td className="small">{product['Vehicle Price']}</td>
                                            <td className="small">{product['Buyer Company']}</td>
                                            <td className="small">{product['Buyer Address']}</td>
                                            <td className="small">{product['Buyer City']}</td>
                                            <td className="small">{product['Total Sales']}</td>
                                            <td className="small">{product['Buyer RFC']}</td>
                                            <td className="small">{product['Verified']}</td>
                                            <td className="small ">
                                                <Link to={`/editproduct/${product.Invoice}`} className="btn btn-sm btn-info mr-1">Edit</Link>
                                                <button onClick={() => deleteProduct(product.Invoice)} className="btn btn-sm btn-danger mt-1">Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Productlist;


